import React from 'react'
import { Link } from 'gatsby'
import ReactFlagsSelect from 'react-flags-select';


class MobileMenu extends React.Component {

  constructor(props) {
    super(props)
    this.flagRef = React.createRef();
    this.onSelectFlag = this.onSelectFlag.bind(this);
  }

  onSelectFlag(countryCode){
    if (countryCode === "US") {
      if(this.defaultLanguage !== countryCode) {
        this.flagRef.current.updateSelected("DE")
        window.location.href = "https://giolom.com";
      }
    } else if (countryCode === "DE") {
      if(this.defaultLanguage !== countryCode) {
        this.flagRef.current.updateSelected("US")
        window.location.href = "https://de.giolom.com";
      }
    }
  }

  render () {
    return (
    <nav>
      {this.props.menuLinks.map(link =>
        link.items ? (
          <React.Fragment key={link.name}>
            <Link className="link depth-0" to={link.link}>
              {link.name}
            </Link>
            <span className="indent-0"></span>
            {link.items.map(sublink =>
              sublink.items ? (
                <React.Fragment key={sublink.name}>
                  <Link
                    style={{ marginLeft: `20px` }}
                    className="link depth-0"
                    to={sublink.link}
                  >
                    {sublink.name}
                  </Link>
                </React.Fragment>
              ) : (
                <div key={sublink.name}>
                  <Link
                    style={{ marginLeft: `20px` }}
                    className="link depth-1"
                    to={sublink.link}
                  >
                    {sublink.name}
                  </Link>
                </div>
              )
            )}
          </React.Fragment>
        ) : (
          <div key={link.name}>
            <Link className="link depth-0" to={link.link}>
              {link.name}
            </Link>
          </div>
        )
      )}
      <div key="selector">
        <Link className="link depth-0">
          <ReactFlagsSelect
            ref={this.flagRef}
            defaultCountry={this.props.defaultLanguage}
            countries={this.props.countries}
            customLabels={{"US": "EN","DE": "DE"}}
            showOptionLabel={false}
            optionsSize={18}
            className="menu-flags-mobile" 
            onSelect={this.onSelectFlag}
          />
        </Link>
      </div>
    </nav>
    )
  }
}

export default MobileMenu
