import React from 'react'
import { Link } from 'gatsby'

const Footer = (props) => {
  const { data } = props
  return (
    <footer id="footer">
      <ul className="icons">
        {/* <li>
          <a href={data.banner.xing} target="_blank" aria-label="Xing" className="icon brands alt fa-xing" rel="noopener noreferrer">
            <span className="label">Xing</span>
          </a>
        </li> */}
        <li>
          <a href={data.banner.freelancermap} target="_blank" aria-label="Freelancermap" className="icon brands alt fa-freelancermap" rel="noopener noreferrer">
            <span className="label">freelancermap</span>
          </a>
        </li>
        <li>
          <a href={data.banner.linkedin} target="_blank" aria-label="Linkedin" className="icon brands alt fa-linkedin-in" rel="noopener noreferrer">
            <span className="label">LinkedIn</span>
          </a>
        </li>
        <li>
          <a href={data.banner.instagram} target="_blank" aria-label="Instagram" className="icon brands alt fa-instagram" rel="noopener noreferrer">
            <span className="label">Instagram</span>
          </a>
        </li>
        <li>
          <a href={"mailto:" + data.banner.mail} aria-label="Email" className="icon solid alt fa-envelope">
            <span className="label">Email</span>
          </a>
        </li>
      </ul>
      <ul className="copyright">
        <li>&copy; {data.footer.copyright}</li>
        <li>
          Design: <a href="http://html5up.net">HTML5 UP</a>
        </li>
        <li>
          {data.footer.images}: <a target="_blank" href="https://unsplash.com" rel="noopener noreferrer">Unsplash</a>, <a target="_blank" href="https://freerangestock.com/" rel="noopener noreferrer">Freerange Stock</a>
        </li>
        <li>
          <Link to="/impressum">{data.footer.impressum}</Link>
        </li>
        <li>
          <Link to="/datenschutz">{data.footer.datenschutz}</Link>
        </li>
      </ul>
    </footer>
  )
}

export default Footer
