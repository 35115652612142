import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet';
import '../assets/scss/main.scss'
import Header from '../components/Header'
import Footer from '../components/Footer'
import contentEN from '../data/front-page-data.json'
import contentDE from '../data/front-page-data.de.json'
if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]');
}

const Layout =  ({ children, pageContext }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            language
          }
        }
      }
    `
  )
  let siteContent = contentEN
  let language = 'en'
  if (site !== undefined) {
    siteContent = site.siteMetadata.language === 'de' ? contentDE : contentEN;
    language = site.siteMetadata.language;
  }
  return (
    <React.Fragment>
      <Helmet
          htmlAttributes={{ lang: language }}
          title={ site.siteMetadata.title }
          titleTemplate={`%s | ${site.siteMetadata.title}`}
          meta={[
            {
              name: `description`,
              content: site.siteMetadata.description
            },
            {
              property: `og:title`,
              content: site.siteMetadata.title
            },
            {
              property: `og:description`,
              content: site.siteMetadata.description
            },
            {
              property: `og:type`,
              content: `website`
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
            {
              name: `twitter:creator`,
              content: site.siteMetadata.author,
            },
            {
              name: `twitter:title`,
              content: site.siteMetadata.title,
            },
            {
              name: `twitter:description`,
              content: site.siteMetadata.description,
            }
          ]
            .concat(
              siteContent.keywords.length > 0
                ? {
                    name: `keywords`,
                    content: siteContent.keywords.join(`, `)
                  }
                : []
            )}
        ></Helmet>
      <div className={pageContext.layout === "landing" ? 'landing' : ''}>
        <div id="page-wrapper">
          <Header
            menuLinks={siteContent.menuLinks}
            siteTitle={siteContent.title}
            language={language}
          />
          <main>{children}</main>
          <Footer data={siteContent} />
        </div>
      </div>
    </React.Fragment>
    )
}

export default Layout